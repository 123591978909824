<template>
  <div v-if="gamesHomePageDataPending">
    <GameCommonSkeleton />
  </div>
  <div class="top-spacer"></div>
  <div v-if="!gamesHomePageDataPending" class="main-content">
    <div class="left-list">
      <div
        v-if="recNewsList != undefined && recNewsList.length > 0"
        class="rec-news"
      >
        <RecNewsModule
          :prop-rec-news-list="recNewsList"
          :prop-top-news-list="topNewsList"
        ></RecNewsModule>
      </div>
      <div
        v-if="recNewsList != undefined && recNewsList.length > 0"
        class="recommend-news-line"
      >
        <div class="horizontal-line" />
      </div>
      <div>
        <div v-if="breadCrumbsList != undefined && breadCrumbsList.length > 0">
          <div class="title-class">
            <span> WIKI </span>
            <NuxtLink :to="jumpNewTab('guide')" class="all-query">
              <span>{{ $t('all') }}</span>
              <NuxtImg
                :src="ImageUtil.getLocalImgFromCdn('ic_arrow_right')"
                alt="see all game wiki"
              />
            </NuxtLink>
          </div>
          <GuideList
            :prop-guide="breadCrumbsList"
            :prop-is-finish="true"
          ></GuideList>
          <div class="horizontal-line" />
        </div>
      </div>
      <div class="title-class">
        <span>{{ $t('allNews') }}</span>
      </div>
      <ArticleList
        :list="newsList"
        class="article-list"
        @click-item="jumpDetail"
      >
      </ArticleList>
      <infinite-loading
        v-show="!isNewsFinish"
        class="infinite-loading"
        target="#app"
        @infinite="loadMore"
      ></infinite-loading>
    </div>
    <div class="right-list">
      <RatingsPanel :rate-list="rateList"></RatingsPanel>
    </div>
  </div>
  <div v-if="loading" class="loading"></div>
</template>

<script lang="ts" setup>
const { $isDevEnv } = useNuxtApp();
import GameCommonSkeleton from '@/components/games/common/game-common-skeleton.vue';
import GuideList from '../guide/guide-list.vue';
import type { NewsListReply } from '@/components/pb/games/games_request';
import type { Breadcrumb, NewsArticle } from '@/components/pb/entity/xgamer.ts';
import RecNewsModule from '@/components/games/homepage/recNews/rec-news-module.vue';
import InfiniteLoading from 'v3-infinite-loading';
import GameUtil from '@/util/game-util.ts';
import RatingsPanel from './ratings-panel.vue';
import 'v3-infinite-loading/lib/style.css';
import ImageUtil from '@/util/image-util.ts';
import ArticleList from '~/components/common/article-list.vue';
import NetworkMgr from '~/util/network-mgr.ts';
const route = useRoute();
//推荐的news包括置顶news和news列表
let topNewsList: NewsArticle[] = ref([]);
let recNewsList: NewsArticle[] = ref([]);
let breadCrumbsList: Breadcrumb[] = ref([]);
//推荐的wiki list
//下面是news信息
let rateList = ref([]);
let newsList: NewsArticle[] = ref([]);
let isNewsFinish = ref(false);
let loadNewsNext = ref('');
let loading = ref(false);
let disabled = ref(false);

const { data: gamesHomePageData, pending: gamesHomePageDataPending }
  = await NetworkMgr.getInstance().request(
    'game_home_page',
    'games/games/homepage',
    {
      game_id: useSelectedGameDetail().value.id,
      begin: '0',
      site_id: 'SITE_BLACK_MYTH'
    },
    { lazy: true }
  );

watch(
  gamesHomePageData,
  (newGamesHomePageData) => {
    if (newGamesHomePageData) {
      if (newGamesHomePageData.topNewsList != undefined) {
        topNewsList.value = newGamesHomePageData.topNewsList as NewsArticle[];
        recNewsList.value = newGamesHomePageData.recoNewsList as NewsArticle[];
        newsList.value = newGamesHomePageData.newsList as NewsArticle[];
      }
      isNewsFinish.value = newGamesHomePageData.newsIsFinish == 1;
      loadNewsNext.value = newGamesHomePageData.newsNext;
      disabled.value = newGamesHomePageData.newsIsFinish == 1;
      //game mod
      //test
      breadCrumbsList.value
        = newGamesHomePageData.recoBreadcrumbs as Breadcrumb[];
      rateList.value = newGamesHomePageData.ratingList;
    }
  },
  { immediate: true }
);

const jumpDetail = (item) => {
  const router = useRouter();
  router.push({ path: newsDetailUrl(item) });
};

const newsDetailUrl = (item) => {
  if (item === undefined) return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  if (item.key != undefined) {
    return `/news/${item.key}`;
  }
  return `/news/${item.id}`;
};

const loadMoreNews = async () => {
  if (loading.value || isNewsFinish.value) {
    console.log(
      'return loading.value : ',
      loading.value,
      ' isNewsFinish.value : ',
      isNewsFinish.value
    );
    return;
  }
  loading.value = true;

  const response = await NetworkMgr.getInstance().fetch(
    'games/games/news_list',
    {
      game_id: useSelectedGameDetail().value.id,
      begin: loadNewsNext.value,
      count: 10,
      site_id: 'SITE_BLACK_MYTH'
    }
  );
  if (response) {
    const newsResp = response as NewsListReply;
    //将newsResp.list增加到newsList.value
    let newsLoad = newsResp.list as NewsArticle[];
    newsList.value.push(...newsLoad);
    isNewsFinish.value = newsResp.isFinish == 1;
    loadNewsNext.value = newsResp.next;
  }
  loading.value = false;
};

const loadMore = async () => {
  await loadMoreNews();
};

const jumpNewTab = (tab: string) => {
  if (tab === undefined) return;
  let gameName = route.params.game;
  if (gameName == undefined) {
    gameName = GameUtil.defaultGameDetail.key;
  }
  return `/${tab}`;
};
const getSeoTitle = () => {
  return `Black Myth : Wukong`;
};

const getSeoDescription = () => {
  return `Black Myth : Wukong`;
};

useSeoMeta({
  title: $isDevEnv() ? '' : getSeoTitle(),
  description: $isDevEnv() ? '' : getSeoDescription(),
  ogTitle: getSeoTitle(),
  ogDescription: getSeoDescription(),
  twitterTitle: getSeoTitle(),
  twitterDescription: getSeoDescription()
});
</script>

<style scoped>
.top-spacer {
  height: 16px;
}
.home-real-root-container {
  width: 100%;
}
.main-content {
  display: flex;
  align-items: flex-start;
}

.left-list {
  padding: 40px 0px 16px 0px;
  flex: 72%;
  background-color: #f6f8f7;
  overflow-x: hidden;
}

.left-list .rec-news {
  display: flex;
  position: relative;
}

.left-list .rec-news .all-query {
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  text-decoration: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.left-list .rec-news .all-query span {
  font-size: 20px;
  font-weight: 500;
  color: #279ff6;
}

.left-list .rec-news .all-query img {
  width: 20px;
}

.left-list .title-class {
  display: flex;
  justify-content: space-between;
  margin: 0px 40px;
}

.left-list .title-class span {
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.9);
}

.left-list .title-class .all-query {
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.left-list .title-class .all-query span {
  font-size: 20px;
  font-weight: 500;
  color: #279ff6;
}

.left-list .title-class .all-query img {
  width: 20px;
}

.right-list {
  flex: 28%;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-shrink: 0;
  gap: 16px;
  position: sticky;
  top: 80px;
}

.article-list {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 16px;
}

.infinite-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-line {
  margin: 20px 40px;
  width: calc(100% - 80px);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 999px) {
  .top-spacer {
    height: 0;
  }

  .home-real-root-container {
    top: 0;
    left: 0;
  }

  .main-content {
    display: flex;
    background-color: #f6f8f7;
  }

  .left-list {
    width: 100vw;
    padding: 0px 0px 16px 0px;
  }

  .left-list .title-class {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 16px;
    width: calc(100vw - 16px);
    align-items: center;
    height: 40px;
  }

  .left-list .title-class span {
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
  }

  .left-list .title-class img {
    width: 12px;
    height: 12px;
  }

  .left-list .title-class .all-query {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 8px;
  }

  .left-list .title-class .all-query span {
    font-size: 16px;
    font-weight: 500;
    color: #279ff6;
  }

  .right-list {
    flex: 0%;
    display: none;
  }

  .article-list {
    padding-left: 8px;
    padding-right: 8px;
  }

  .infinite-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .horizontal-line {
    width: calc(100% - 16px);
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 8px;
    margin-right: 8px;
  }
  .article-list {
    padding-left: 8px;
    padding-right: 8px;
  }
  .recommend-news-line {
    display: none;
  }
}
</style>
